import React from 'react';
import * as Sentry from "@sentry/react";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import Grid from '@mui/material/Grid2'
import { useReportCompareContext } from "../../contexts/ReportCompare.context";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";
import toast from "react-hot-toast";

import { ColumnType, ReportType } from '../../types'
import ReportApi from '../../api/Report.api'
import ReportStatus from "../ReportStatus";
import { HttpError } from "../../types/http-error";
import EmailDrawer from './EmailDrawer'

export default function ReportCompareHeader({ reportType }: { reportType: ReportType }) {
  const {
    mainVarieties,
    isLoading,
    filteredCombinedVarieties,
    searchByWetDate,
    varieties,
    displayShipper,
    displayRanch,
    displaySupplier,
    displayGrower
  } = useReportCompareContext();
  const [showDrawer, setShowDrawer] = React.useState<boolean>(false)
  const [reportId, setReportId] = React.useState<number | undefined>(undefined);

  const varietyIds: string[] = varieties ? Object.keys(varieties) : [];

  const fieldVarietyIds = filteredCombinedVarieties ? filteredCombinedVarieties.filter((columnType: ColumnType) =>
    columnType.evaluableType === 'field_variety' ? true : false
  ).map((columnType: ColumnType) => columnType.typeVariety.id) : [];
  const trialVarietyIds = filteredCombinedVarieties ? filteredCombinedVarieties.filter((columnType: ColumnType) =>
    columnType.evaluableType === 'trial_variety' ? true : false
  ).map((columnType: ColumnType) => columnType.typeVariety.id) : []

  const { mutateAsync: mutatePdf } = ReportApi.useCreate({
    reportType,
    fieldVarietyIds,
    trialVarietyIds,
    varietyIds,
    orderByWetDate: searchByWetDate || true,
    hideGrower: !displayGrower,
    hideShipper: !displayShipper,
    hideSupplier: !displaySupplier,
    hideRanch: !displayRanch
  })

  const { mutateAsync: mutateExcel } = ReportApi.useCreateExcel({
    reportType,
    fieldVarietyIds,
    trialVarietyIds,
    varietyIds,
  })

  const { mutateAsync: mutateEmail } = ReportApi.useSendEmail({
    reportType,
    fieldVarietyIds,
    trialVarietyIds,
    varietyIds,
    orderByWetDate: searchByWetDate || true,
    hideGrower: !displayGrower,
    hideShipper: !displayShipper,
    hideSupplier: !displaySupplier,
    hideRanch: !displayRanch
  })

  const handleCreatePdf = async () => {
    try {
      const result = await mutatePdf()
      console.log('result', result)
      setReportId(result.id)
    } catch(error) {
      console.error(error)
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to print Report");
      }
    }
  }

  const handleCreateExcel = async () => {
    try {
      const result = await mutateExcel()
      console.log('result', result)
      setReportId(result.id)
    } catch(error) {
      console.error(error)
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to print Report");
      }
    }
  }

  const handleSendEmail = async (peopleIds: number[]) => {
    await mutateEmail(peopleIds)
  }

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 6 }}>
        {mainVarieties &&
          Object.values(mainVarieties).map(variety => (
            <Typography key={variety.id} variant={"h3"}>
              {variety.name}
            </Typography>
          ))}
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button variant={"contained"} color={"primary"} size={"large"} onClick={() => setShowDrawer(true)}>
            <EmailIcon />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button variant={"contained"} color={"primary"} size={"large"} onClick={handleCreateExcel}>
            <DescriptionIcon />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button variant={"contained"} color={"primary"} size={"large"} onClick={handleCreatePdf}>
            <PrintIcon />
          </Button>
        </Box>
      </Grid>
      {reportId && (
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start"}}>
          <ReportStatus reportId={reportId} />
        </Box>
      )}
      {isLoading && (
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <CircularProgress size={80} thickness={7} color={"primary"} />
        </Box>
      )}

      {!isLoading && <EmailDrawer sendEmail={handleSendEmail} showDrawer={showDrawer} handleClose={() => setShowDrawer(false)} /> }
    </Grid>
  );
}
