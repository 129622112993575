import { handleBasicGet, handleBasicPost } from "./handler";
import { useQuery, useMutation } from "react-query";
import { ReportStatus, ReportType } from "../types";

const methods = {
  useStatus: ({ id }: { id?: number }) => {
    return useQuery({
      queryKey: ["report", id?.toString()],
      queryFn: () => handleBasicGet<ReportStatus>({ url: `reports/${id}` }),
      enabled: !!id,
      cacheTime: 0,
    });
  },
  useCreate: ({
    reportType,
    fieldVarietyIds,
    trialVarietyIds,
    varietyIds,
    hideGrower,
    hideShipper,
    hideSupplier,
    hideRanch,
    orderByWetDate
  }: {
    reportType: ReportType;
    fieldVarietyIds: number[];
    trialVarietyIds: number[];
    varietyIds: string[];
    hideGrower: boolean;
    hideShipper: boolean;
    hideSupplier: boolean;
    hideRanch: boolean;
    orderByWetDate: boolean;
  }) => {
    return useMutation({
      mutationFn: () => handleBasicPost({
        url: "reports",
        input: {
          reportType,
          fieldVarietyIds,
          trialVarietyIds,
          varietyIds,
          hideList: {
            grower: hideGrower,
            shipper: hideShipper,
            supplier: hideSupplier,
            ranch: hideRanch
          },
          extra: {
            orderByWetDate
          }
        }
      }),
      retry: 1
    })
  },
  useCreateExcel: ({
    reportType,
    fieldVarietyIds,
    trialVarietyIds,
    varietyIds,
  }: {
    reportType: ReportType;
    fieldVarietyIds: number[];
    trialVarietyIds: number[];
    varietyIds: string[];
  }) => {
    return useMutation({
      mutationFn: () => handleBasicPost({
        url: "reports/excel",
        input: {
          reportType,
          fieldVarietyIds,
          trialVarietyIds,
          varietyIds
        }
      }),
      retry: 1
    })
  },
  useSendEmail: ({
    reportType,
    fieldVarietyIds,
    trialVarietyIds,
    varietyIds,
    hideGrower,
    hideShipper,
    hideSupplier,
    hideRanch,
    orderByWetDate
  }: {
    reportType: ReportType;
    fieldVarietyIds: number[];
    trialVarietyIds: number[];
    varietyIds: string[];
    hideGrower: boolean;
    hideShipper: boolean;
    hideSupplier: boolean;
    hideRanch: boolean;
    orderByWetDate: boolean;
  }) => {
    return useMutation({
      mutationFn: (peopleIds: number[]) => handleBasicPost({
        url: "reports/email",
        input: {
          reportType,
          fieldVarietyIds,
          trialVarietyIds,
          varietyIds,
          peopleIds,
          hideList: {
            grower: hideGrower,
            shipper: hideShipper,
            supplier: hideSupplier,
            ranch: hideRanch
          },
          extra: {
            orderByWetDate
          }
        }
      }),
      retry: 1
    })
  }
};
export default methods;
