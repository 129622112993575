import {
  Button,
  Checkbox,
  Drawer,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import React from 'react'
import { useReportCompareContext } from '../../contexts/ReportCompare.context'
import CompanyApi from '../../api/Company.api'
import { ColumnType, Person, Company, Trial } from '../../types'
import { uniqBy, remove, isEmpty } from 'lodash'
import toast from "react-hot-toast";

export default function EmailDrawer({
  showDrawer,
  handleClose,
  sendEmail
}: {
  showDrawer: boolean;
  handleClose: () => void;
  sendEmail: (peopleIds: number[]) => Promise<void>;
}){
  const [checked, setChecked] = React.useState<number[]>([]);

  const { varieties, filteredCombinedVarieties } = useReportCompareContext()
  const trials: Trial[] = filteredCombinedVarieties ?
    filteredCombinedVarieties.map((columnType: ColumnType) => columnType.typeVariety.trial) :
    [];
  const companyIds: number[] = [];
  trials.forEach((trial: Trial) => {
    if(trial.growerId) {
      companyIds.push(trial.growerId);
    }
    if(trial.shipperId) {
      companyIds.push(trial.shipperId);
    }
  })
  const { data: companies } = CompanyApi.useByIds({
    companyIds
  })
  let people: Person[] = [];
  if(companies) {
    companies.forEach((company: Company) => {
      people = people.concat(company.people)
    })
    people = uniqBy(people, 'id')
  }

  const handleToggle = (personId: number) => (event: any) => {
    setChecked(prevChecked => {
      if (prevChecked.indexOf(personId) === -1) {
        prevChecked.push(personId);
        return [...prevChecked];
      }
      return remove(prevChecked, (n: number) => {
        return n !== personId;
      });
    });
  };

  const handleSendEmail = async () => {
    if(isEmpty(checked)){
      return ;
    }
    try {
      await sendEmail(checked)
      handleClose()
      toast.success("Successfully sent.");
    } catch(e){
      console.error(e);
      Sentry.captureException(e);
      toast.error("Failed to send email");
    }
  }

  console.log('checked', checked)
  return (
    <Drawer anchor="bottom" open={showDrawer} onClose={handleClose}>
      <FormControl component={"fieldset"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Table>
              <TableBody>
                {people &&
                  people
                    .sort((a: Person, b: Person) =>
                      a.lastName.toUpperCase() < b.lastName.toUpperCase()
                        ? -1
                        : 1
                    )
                    .map((person: Person) => (
                      <TableRow key={person.id}>
                        <TableCell align={"left"}>
                          <Checkbox
                            checked={checked.indexOf(person.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            onClick={handleToggle(person.id)}
                          />
                        </TableCell>
                        <TableCell>
                          {person.firstName} {person.lastName}
                        </TableCell>
                        <TableCell>{person.email}</TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </FormControl>
      <FormControl component="fieldset" sx={{ p: "2rem" }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleSendEmail}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </Drawer>
  )
}